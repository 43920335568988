import {action, makeAutoObservable, observable} from 'mobx'
import {ICallToActionTabella, IColonnaTabellaInterattiva} from '../types'

export class TabellaInterattivaStore {
    titolo: string = ''
    sottoTitolo: string = ''
    paragrafo: string = ''
    callToActions: ICallToActionTabella[] = []
    callToActionsRiga: ICallToActionTabella[] = []
    colonne?: IColonnaTabellaInterattiva[] = []
    elementiPerPagina: number = 0
    paginaAttiva: number = 0
    modalitaModificaRapida: boolean = true
    endpoint: string = ''
    pk: string[] = []
    stringaFiltro: string = ''
    exportable: boolean = true

    synergical?: boolean = false
    caricamentoFinito?: boolean = false

    dati: any = []
    datiVisibili: any = []
    colonnaOrdinamento: string = ''
    direzioneOrdinamento: string = ''

    constructor() {
        makeAutoObservable(this, {
            settaDati: action,
            datiVisibili: observable,

            stringaFiltro: observable,
            settaStringaFiltro: action,
            filtraDati: action,

            cambiaPagina: action,
            settaElementiPerPagina: action,

            colonnaOrdinamento: observable,
            direzioneOrdinamento: observable,
            cambiaOrdinamento: action
        })
    }

    settaDati = (dati: any[]) => {

        this.dati = dati

        this.paginaDati(
            this.paginaAttiva * this.elementiPerPagina,
            (this.paginaAttiva * this.elementiPerPagina) + this.elementiPerPagina
        )

        this.filtraDati()
    }

    settaStringaFiltro = (filtro: string) => {
        this.stringaFiltro = filtro
        this.filtraDati()
    }

    filtraDati = () => {

        if (this.dati && this.dati.length > 0) {
            if (this.stringaFiltro) {
                this.datiVisibili = this.dati.filter((dato: object) => Object.values(dato).toString().includes(this.stringaFiltro))

            } else {
                this.paginaAttiva = 0
                this.paginaDati(
                    this.paginaAttiva * this.elementiPerPagina,
                    (this.paginaAttiva * this.elementiPerPagina) + this.elementiPerPagina
                )
            }
        }

    }
    cambiaPagina = (pagina: number) => {
        this.paginaAttiva = pagina

        this.paginaDati(
            this.paginaAttiva * this.elementiPerPagina,
            (this.paginaAttiva * this.elementiPerPagina) + this.elementiPerPagina
        )
    }

    paginaDati = (da: number, a: number) => {
        if (this.dati && this.dati.length > 0) {
            this.datiVisibili = this.dati.slice(da, a)
        }
    }

    settaElementiPerPagina = (elementi: number) => {
        this.elementiPerPagina = elementi

        this.paginaDati(
            this.paginaAttiva * this.elementiPerPagina,
            (this.paginaAttiva * this.elementiPerPagina) + this.elementiPerPagina
        )
    }

    cambiaOrdinamento = (colonna: string, direzione: 'ASC' | 'DESC') => {

        this.colonnaOrdinamento = colonna
        this.direzioneOrdinamento = direzione

        //@ts-ignore
        this.settaDati(this.dati.sort((a: object, b: object) => direzione === 'DESC' ? a[colonna] > b[colonna] ? 1 : -1 : a[colonna] < b[colonna] ? 1 : -1))
    }
}
