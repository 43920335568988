import React, {useEffect, useState} from 'react';

//Data
import {useForm, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useStores} from '../../../../../hooks/useStores';
import {crea, modifica} from '../../../../../rest/crud_generica';
import {ICaricamentoMassivo} from '../../../types';

//Components
import ErrorBox from '../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../components/common/InfoBox';
import SpinnerButton from '../../../../../components/common/SpinnerButton';

const RendicontazioneCaricamentoMassivoValidazioneForm: React.FC<{
    businessUnit?: string,
    anno: number,
    paese: string,
    caricamentoMassivo?: ICaricamentoMassivo,
}> = ({
          caricamentoMassivo,
          businessUnit,
          anno,
          paese
      }) => {

    const SCOPE_ID = 3;

    const {t} = useTranslation()
    const {ui} = useStores()
    const [attesa, setAttesa] = useState<boolean>(false)
    const [successo, setSuccesso] = useState<string>('')
    const [errore, setErrore] = useState<string>('')
    const listaOpzioniTonnellate = [
        {value: 0, label: t('ModuloCarbonFootprint.rendCaricamento.form.tMetrics')},
        {value: 1, label: t('ModuloCarbonFootprint.rendCaricamento.form.tAmericans')},
    ];
    const listaOpzioniSpedizioniAggregate = [
        {value: 1, label: t('ModuloCarbonFootprint.rendCaricamento.form.aggregated')},
        {value: 0, label: t('ModuloCarbonFootprint.rendCaricamento.form.notAggregated')},
    ];

    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        formState,
        formState: {errors, isSubmitSuccessful}
    } = useForm({
        defaultValues: {
            metrica_americana: caricamentoMassivo ? caricamentoMassivo.metrica_americana : false,
            spedizioni_aggregate: caricamentoMassivo ? caricamentoMassivo.spedizioni_aggregate : false,
        }
    });

    const {fields, append, remove} = useFieldArray<any>({
        control,
        name: 'testheck',
    });

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
        }
    }, [formState, reset])

    const onSubmit = (data: any) => {
        setErrore('');
        setSuccesso('');
        setAttesa(true)

        let newValidazione: any[] = [];

        Object.keys(data).map((key: any, index) => {
            //const index = caricamentoMassivo?.validazione.findIndex(elem => elem.id === key);
            if (key != 'testheck' && key != 'metrica_americana' && key != 'spedizioni_aggregate') {
                var obj = {
                    id: caricamentoMassivo?.validazione[index].id,
                    label: caricamentoMassivo?.validazione[index].label,
                    value: data[key]
                }

                newValidazione.push(obj);
            }
        });

        const mydata = {
            validazione: newValidazione,
            metrica_americana: data.metrica_americana.toString(),
            // spedizioni_aggregate: data.spedizioni_aggregate.toString(),
        }

        if (caricamentoMassivo) {
            modifica(`carbon_footprint/rendicontazione/caricamento_massivo/${SCOPE_ID}/${businessUnit?.toString()}/${anno.toString()}/${caricamentoMassivo.id}`, mydata)
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendCaricamento.form.successoModifica'))
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
        }
    }

    return (
        <div>
            <div>
                {caricamentoMassivo
                    ? <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendCaricamento.form.titoloModificaValidizone')} {businessUnit}</h2>
                    : <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendCaricamento.form.titoloCreazioneValidazione')} {businessUnit}</h2>
                }
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>

                {caricamentoMassivo?.validazione.map((el: any, index: any) => {
                    return (
                        <div className='miaform'>
                            <div key={el.id} className="mb-2">
                                <label className="inline-block mr-4">
                                    <input
                                        type="checkbox"
                                        {...register(el.id.toString())}
                                        id={`check-${el.id}`}
                                        defaultChecked={el.value || false}
                                        className='mr-2'
                                    />
                                    {el.label || ""}
                                </label>
                            </div>
                        </div>
                    )
                })}

                <div className="w-full bg-lightgray-d dark:bg-darkgray-d p-3 rounded-md my-2">
                    <h4>{t('ModuloCarbonFootprint.rendCaricamento.form.metricaAmericana')}</h4>
                    {
                        listaOpzioniTonnellate.map((item, index) => (
                            <label>
                                <input name="metrica_americana" type="radio" value={item.value}
                                       style={{display: 'inline-block', width: '1rem'}} required={true}/>
                                {item.label}
                            </label>
                        ))
                    }
                </div>

                {/*<div className="w-full bg-lightgray-d dark:bg-darkgray-d p-3 rounded-md my-2">*/}
                {/*    <h4>{t('ModuloCarbonFootprint.rendCaricamento.form.spedizioniAggregate')}</h4>*/}
                {/*    {*/}
                {/*        listaOpzioniSpedizioniAggregate.map((item, index) => (*/}
                {/*            <label>*/}
                {/*                <input name="spedizioni_aggregate" type="radio" value={item.value}*/}
                {/*                       style={{display: 'inline-block', width: '1rem'}} required={true}/>*/}
                {/*                {item.label}*/}
                {/*            </label>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</div>*/}

                {
                    !attesa
                        ? <input type="submit" value={t('common.modifica')}/>
                        : <SpinnerButton/>
                }

                {(errore || successo) &&
                    <div className="mt-2">
                        {errore && <ErrorBox errore={errore}/>}
                        {successo && <InfoBox messaggio={successo}/>}
                    </div>
                }

            </form>
        </div>
    )
}

export default RendicontazioneCaricamentoMassivoValidazioneForm;