import React, {useEffect, useState} from 'react';

//Data
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useStores} from '../../../../../hooks/useStores';
import {crea, modifica} from '../../../../../rest/crud_generica';
import {ICaricamentoMassivo} from '../../../types';

//Components
import ErrorBox from '../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../components/common/InfoBox';
import SpinnerButton from '../../../../../components/common/SpinnerButton';
import InputField from '../../../../../components/common/form/InputField';

const RendicontazioneCaricamentoMassivoForm: React.FC<{
    businessUnit?: string,
    anno: number,
    paese: string,
    caricamentoMassivo?: ICaricamentoMassivo,
}> = ({
          caricamentoMassivo,
          businessUnit,
          anno,
          paese,
      }) => {

    const SCOPE_ID = 3;

    const {t} = useTranslation()
    const {ui} = useStores()
    const [attesa, setAttesa] = useState<boolean>(false)
    const [successo, setSuccesso] = useState<string>('')
    const [errore, setErrore] = useState<string>('')
    const listaOpzioniTonnellate = [
        {value: 0, label: t('ModuloCarbonFootprint.rendCaricamento.form.tMetrics')},
        {value: 1, label: t('ModuloCarbonFootprint.rendCaricamento.form.tAmericans')},
    ];
    const listaOpzioniSpedizioniAggregate = [
        {value: 1, label: t('ModuloCarbonFootprint.rendCaricamento.form.aggregated')},
        {value: 0, label: t('ModuloCarbonFootprint.rendCaricamento.form.notAggregated')},
    ];

    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        formState,
        formState: {errors, isSubmitSuccessful}
    } = useForm({
        defaultValues: {
            file: null,
            compilatore: caricamentoMassivo ? caricamentoMassivo.compilatore : '',
            note: caricamentoMassivo ? caricamentoMassivo.note : '',
            metrica_americana: caricamentoMassivo ? caricamentoMassivo.metrica_americana : false,
            spedizioni_aggregate: caricamentoMassivo ? caricamentoMassivo.spedizioni_aggregate : false,
        }
    });

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({file: null})
        }
    }, [formState, reset])

    const onSubmit = (data: any) => {
        setErrore('');
        setSuccesso('');
        setAttesa(true)
        const formData = new FormData();
        const file = watch('file');
        if (!file) {
            setErrore(t('ModuloCarbonFootprint.rendCaricamento.form.fileinesistente'))
            alert('NOT FILE');
            setAttesa(false)
            return
        }

        formData.append("file", file[0]);
        formData.append("scope_id", SCOPE_ID.toString());
        formData.append("business_unit", businessUnit?.toString() || '');
        formData.append("anno", anno.toString());
        formData.append("metrica_americana", data.metrica_americana.toString());
        formData.append("spedizioni_aggregate", data.spedizioni_aggregate.toString());

        caricamentoMassivo
            ?
            modifica(`carbon_footprint/rendicontazione/caricamento_massivo/${caricamentoMassivo.id}`, formData,
                {"Content-Type": "multipart/form-data"})
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendCaricamento.form.successoModifica'))
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
            :
            crea(`carbon_footprint/rendicontazione/caricamento_massivo`, formData,
                {"Content-Type": "multipart/form-data"})
                .then((res) => {
                    setErrore('');
                    setSuccesso(res.data.message ? res.data.message : t('ModuloCarbonFootprint.rendCaricamento.form.successoCreazione'))
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : t('common.erroreGenerico'))
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
    }

    return (
        <div>
            <div>
                {caricamentoMassivo
                    ? <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendCaricamento.form.titoloModifica')} {businessUnit}</h2>
                    : <h2 className="text-2xl mb-2">{t('ModuloCarbonFootprint.rendCaricamento.form.titoloCreazione')} {businessUnit}</h2>
                }
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>

                <InputField
                    label="Upload file"
                    type="file"
                    error=""
                    classi=''
                    form={register("file", {required: t('common.fileObbligatorio')},)}
                    placeholder="Seleziona il file da caricare"
                    required={true}
                />

                <div className="w-full bg-lightgray-d dark:bg-darkgray-d p-3 rounded-md my-2">
                    <h4>{t('ModuloCarbonFootprint.rendCaricamento.form.metricaAmericana')}</h4>
                    {
                        listaOpzioniTonnellate.map((item, index) => (
                            <label>
                                <input name="metrica_americana" type="radio" value={item.value} style={{display: 'inline-block', width: '1rem'}} required={true}/>
                                {item.label}
                            </label>
                        ))
                    }
                </div>

                {/*<div className="w-full bg-lightgray-d dark:bg-darkgray-d p-3 rounded-md my-2">*/}
                {/*    <h4>{t('ModuloCarbonFootprint.rendCaricamento.form.spedizioniAggregate')}</h4>*/}
                {/*    {*/}
                {/*        listaOpzioniSpedizioniAggregate.map((item, index) => (*/}
                {/*            <label>*/}
                {/*                <input name="spedizioni_aggregate" type="radio" value={item.value} style={{display: 'inline-block', width: '1rem'}} required={true}/>*/}
                {/*                {item.label}*/}
                {/*            </label>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</div>*/}

                {
                    !attesa
                        ? <input type="submit" value={"Carica File"}/>
                        : <SpinnerButton/>
                }

                {(errore || successo) &&
                    <div className="mt-2">
                        {errore && <ErrorBox errore={errore}/>}
                        {successo && <InfoBox messaggio={successo}/>}
                    </div>
                }

            </form>
        </div>
    )
}

export default RendicontazioneCaricamentoMassivoForm;
