import React, {useState, useEffect} from 'react'

//Data
import {useStores} from '../../../../../hooks/useStores'
import {crea, elimina, get} from '../../../../../rest/crud_generica'
import {ICaricamentoMassivo} from '../../../types'
import {ICallToActionTabella} from '../../../../../components/tabellaInterattiva/types'
import {IBusinessUnitRendicontabile} from '../../../../UiModuloFattoriESG/types'
import {useTranslation} from 'react-i18next'
import {observer} from 'mobx-react'

//Components
import {FaInfoCircle, FaPen, FaTrash, FaDownload} from 'react-icons/fa'
import {BsPlay} from 'react-icons/bs'
import {CgAdd} from 'react-icons/cg'
import Dialogue from '../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import RendicontazioneCaricamentoMassivoForm from './RendicontazioneCaricamentoMassivoForm'
import Spinner from '../../../../../components/common/Spinner'
import {useStatoRendicontazioneContext} from '../../context/useStatoRendicontazioneContext'
import RendicontazioneCaricamentoMassivoValidazioneForm from './RendicontazioneCaricamentoMassivoValidazioneForm'


const RendicontazioneCaricamentoMassivo = observer(() => {

    const SCOPE_ID = 3;
    const {t} = useTranslation()
    const {ui} = useStores()
    const {stato_rendicontazione} = useStatoRendicontazioneContext()

    //Lista delle bu
    const [businessUnits, setBusinessUnits] = useState<IBusinessUnitRendicontabile[]>([])
    const [caricamento, setCaricamento] = useState<boolean>(false)

    //Ottiene lista bu
    useEffect(() => {
        setCaricamento(true)

        get('lista_ruoli_utente').then((res) => {
            setBusinessUnits(res.data.data)
            cambiaBuAttiva(res.data.data[0])
        }).finally(() => {
            setCaricamento(false)
        })
    }, [])

    //BU attiva
    const [businessUnitAttiva, setBusinessUnitAttiva] = useState<IBusinessUnitRendicontabile>()

    //Cambia BU attiva
    const cambiaBuAttiva = (bu: IBusinessUnitRendicontabile) => {
        setBusinessUnitAttiva(bu)
        setPaese(bu.paese)
        ui.triggerAggiornamentoGenerico()
    }

    const [paese, setPaese] = useState<string>('Italy')

    const [caricamentiMassivi, setCaricamentiMassivi] = useState<ICaricamentoMassivo[]>([])

    useEffect(() => {
        businessUnitAttiva &&
        get(`carbon_footprint/rendicontazione/caricamento_massivo/${SCOPE_ID}/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}`)
            .then((res) => {
                // Modify Response for Table Samuele
                res.data.data.map((el: any) => {
                    let sValidazione = '';
                    el.validazione.map((el1: any) => {
                        (el1.value == false) ? sValidazione += ' ( ' + el1.label + ' - NO )' : sValidazione += ' ( ' + el1.label + ' - SI )'
                    })
                    sValidazione.slice(0, sValidazione.length - 1);
                    el.validazioneTable = sValidazione;
                })

                setCaricamentiMassivi(res.data.data)
            })

    }, [businessUnitAttiva, ui.aggiornamentoGenerico, stato_rendicontazione.annoAttivo])

    //Crea emissione
    const creaCaricamentoMassivo = () => {
        businessUnitAttiva &&
        ui.mountAsideModal(
            <RendicontazioneCaricamentoMassivoForm
                businessUnit={businessUnitAttiva.nome}
                anno={stato_rendicontazione.annoAttivo}
                paese={paese}
            />
        )
    }

    //Modifica emissione
    const modificaCaricamentoMassivo = (caricamento: ICaricamentoMassivo) => {
        businessUnitAttiva &&
        ui.mountAsideModal(
            <RendicontazioneCaricamentoMassivoValidazioneForm
                businessUnit={businessUnitAttiva.nome}
                anno={stato_rendicontazione.annoAttivo}
                paese={paese}
                caricamentoMassivo={caricamento}
            />
        )
    }

    //Elimina emissione
    const eliminaCaricamentoMassivo = (caricamento: ICaricamentoMassivo) => {
        businessUnitAttiva &&
        elimina(`carbon_footprint/rendicontazione/caricamento_massivo/${SCOPE_ID}/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${caricamento.id}`)
            .then(() => {
                ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCaricamento.successoEliminazione')}/>)
                ui.triggerAggiornamentoGenerico()
            })
            .catch(() => {
                ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCaricamento.erroreEliminazione')}/>)
            })
    }

    //Valida emissione
    const elaboraCaricamentoMassivo = (caricamento: ICaricamentoMassivo) => {
        businessUnitAttiva &&
        crea(`carbon_footprint/rendicontazione/caricamento_massivo/${SCOPE_ID}/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${caricamento.id}/elabora`)
            .then(() => {
                ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCaricamento.successoAvvio')}/>)
                ui.triggerAggiornamentoGenerico()
            })
            .catch(() => {
                ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCaricamento.erroreAvvio')}/>)
            })
    }

    const downloadCaricamentoMassivo = (caricamento: ICaricamentoMassivo) => {
        businessUnitAttiva &&
        get(`carbon_footprint/rendicontazione/caricamento_massivo/${SCOPE_ID}/${businessUnitAttiva.nome}/${stato_rendicontazione.annoAttivo}/${caricamento.id}/download`)
            .then((response) => {
                if (response.data?.url !== '') {
                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = response.data?.url;
                    link.setAttribute('download', '');
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                }

            }).catch(() => {
            ui.mountDialogue(<Dialogue titolo={t('ModuloCarbonFootprint.rendCaricamento.erroreDownload')}/>)
        });
    }

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (businessUnitAttiva) {
            setCtaRiga([])
            setCtaTabella([])

            setCtaRiga(cta => [...cta, {
                id: 'download',
                testo: t('ModuloCarbonFootprint.rendCaricamento.download'),
                azione: downloadCaricamentoMassivo,
                icona: <FaDownload size={12}/>
            }])

            if (businessUnitAttiva.ruolo >= 1) {

                setCtaRiga(cta => [...cta, {
                    id: 'modifica',
                    testo: t('common.modifica'),
                    azione: modificaCaricamentoMassivo,
                    icona: <FaPen size={12}/>,
                }])

                setCtaTabella(cta => [...cta, {
                    id: 'crea',
                    testo: t('ModuloCarbonFootprint.rendCaricamento.aggiungi'),
                    azione: creaCaricamentoMassivo,
                    icona: <CgAdd size={18}/>
                }])
            }

            if (businessUnitAttiva.ruolo >= 2) {

                setCtaRiga(cta => [...cta, {
                    id: 'elimina',
                    testo: t('common.elimina'),
                    azione: eliminaCaricamentoMassivo,
                    icona: <FaTrash size={12}/>,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])

                setCtaRiga(cta => [...cta, {
                    id: 'elabora',
                    testo: t('common.elabora'),
                    azione: elaboraCaricamentoMassivo,
                    icona: <BsPlay size={12}/>,
                    coloreSfondo: "bg-green dark:bg-dark-green"
                }])
            }
        }
    }, [businessUnitAttiva])

    return (
        <div className="flex flex-col">

            {caricamento &&
                <div className='flex items-center justify-center p-4 w-full'>
                    <Spinner colore='blue'/>
                </div>
            }

            {businessUnits && businessUnits.length > 0 && !caricamento &&
                <div className="flex max-w-[90vw] sm:max-w-[450px] md:max-w-[600px] overflow-x-auto">
                    {
                        businessUnits.map((bu) => (
                            <div
                                key={bu.nome}
                                onClick={() => cambiaBuAttiva(bu)}
                                onMouseEnter={() => bu.nome.length > 30 && ui.mountFollowMouse(<>{bu.nome}</>)}
                                onMouseLeave={() => ui.unmountFollowMouse()}
                                className={`
                                p-1 px-4 py-2 cursor-pointer duration-200
                                ${businessUnitAttiva && businessUnitAttiva.nome === bu.nome ?
                                    'bg-accent dark:bg-dark-accent pointer-events-none text-white'
                                    :
                                    'bg-white dark:bg-darkgray-dd opacity-100 hover:opacity-70'
                                }`}>
                                {bu.nome.substring(0, 30)}
                                {bu.nome.length > 30 && '...'}
                            </div>
                        ))
                    }
                </div>
            }

            {businessUnits.length === 0 && !caricamento &&
                <div className='flex flex-col gap-2 items-center max-w-[500px] mx-auto'>
                    <FaInfoCircle/>
                    <h2 className="text-center text-lg">{t('ModuloCarbonFootprint.common.nonHaiAccesso')}</h2>
                </div>
            }

            {
                businessUnitAttiva && businessUnits.length > 0 &&
                <div className="grow border-t-2 border-accent dark:border-dark-accent">
                    <TabellaInterattiva
                        synergical={true}
                        dati={caricamentiMassivi}
                        titolo={`${t('ModuloCarbonFootprint.rendCaricamento.titolo')} ${businessUnitAttiva.nome}`}
                        callToActions={ctaTabella}
                        colonne={
                            [
                                {id: 'revisione', label: t('common.revisione')},
                                {id: 'filename', label: t('common.filename')},
                                {id: 'compilatore', label: t('common.compilatore')},
                                {id: 'note', label: t('common.note')},
                                {id: 'validazioneTable', label: t('common.validazione')}
                            ]
                        }
                        callToActionsRiga={ctaRiga}
                        exportable={false}
                    />
                </div>
            }

        </div>
    )
})
export default RendicontazioneCaricamentoMassivo;
